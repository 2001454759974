<template>
  <div class="mine_page">
    <div class="mine_bg">
      <div class="mine_top" @click="onLogin">
        <div class="mine_top_left">
          <img
            v-if="isLogin && userInfo.userDetailEntity.profilePicture"
            class="portrait"
            :src="userInfo.userDetailEntity.profilePicture"
            alt=""
          />
          <img
            v-else
            class="portrait"
            src="../../assets/images/portrait_icon.png"
            alt=""
          />

          <div class="mine_name">
            <span v-if="isLogin"
              >{{ userInfo.userEntity.username | Tel }}
              <img
                class="navbar_dcs"
                src="../../assets/images/navbar_dcs_back.png"
                alt=""
            /></span>
            <span v-else>登录/注册</span>
            <div v-if="bank.length">
              <i
                v-for="(item, i) in bank"
                :key="i"
                v-show="item.bankId !== '0'"
              >
                <img src="@/assets/images/vip.png" alt="" />
                {{ item.bankName }}VIP
              </i>
            </div>
          </div>
          <div
            class="sign_in"
            @click.stop="
              isLogin
                ? $router.push({
                    path: '/userData/signIn',
                    query: {
                      automaticCheckIn: '1',
                    },
                  })
                : $router.push('/login')
            "
          >
            每日签到
          </div>
          <!-- <span v-show="actualName">会员：{{actualName}}</span> -->
        </div>
      </div>
      <div class="order">
        <div class="order_top">
          <span class="purchase">我的采购订单</span>
          <span @click="$router.push('/myOrder')"
            >查看全部订单<img src="../../assets/images/more_icon.png" alt=""
          /></span>
        </div>
        <div class="order_list">
          <div class="list" @click="$router.push('/myOrder?i=0')">
            <img src="../../assets/images/dfk_icon.png" alt="" />
            <span>待付款</span>
          </div>
          <div class="list" @click="$router.push('/myOrder?i=3')">
            <img src="../../assets/images/dfh_icon.png" alt="" />
            <span>待自提</span>
          </div>
          <div class="list" @click="$router.push('/myOrder?i=8')">
            <img src="../../assets/images/dsh_icon.png" alt="" />
            <span>待收货</span>
          </div>
          <div class="list">
            <img src="../../assets/images/th_icon.png" alt="" />
            <span>退款/售后</span>
          </div>
        </div>
      </div>
      <div class="order">
        <div class="order_top">
          <span class="purchase">日常使用</span>
          <span></span>
        </div>
        <div class="order_list">
          <!-- <div class="list">
            <img src="../../assets/images/xf_icon.png" alt="" />
            <span>消费账单</span>
          </div> -->
          <div class="list" @click="fyTips">
            <img src="../../assets/images/fy_icon.png" style="width: 22px" />
            <span>分佣推广</span>
          </div>
          <div class="list" @click="$router.push('/userData/wallet')">
            <img
              src="../../assets/images/qb_icon.png"
              alt=""
              style="width: 22px"
            />
            <span>我的钱包</span>
          </div>
          <div
            class="list"
            v-if="maxIntegralBank.bankId && $store.state.App"
            @click="$router.push('/pointsMall')"
          >
            <img
              src="../../assets/images/wdjf_icon.png"
              alt=""
              style="width: 22px"
            />
            <span>积分商城</span>
          </div>
          <div class="list" @click="$router.push('/userData/address')">
            <img
              src="../../assets/images/shdz_icon.png"
              alt=""
              style="width: 22px"
            />
            <span>收货地址</span>
          </div>
          <!-- <div class="list">
            <img src="../../assets/images/yqxz_icon.png" alt="" />
            <span>邀请下载</span>
          </div> -->

          <div class="list" @click="toSet">
            <img
              src="../../assets/images/sz_icon.png"
              alt=""
              style="width: 22px"
            />
            <span>设置</span>
          </div>

          <!-- <div class="list">
            <img src="../../assets/images/qt_icon.png" alt="" />
            <span>其他模块</span>
          </div> -->
        </div>
        <div class="order_list"></div>
      </div>
      <img
        src="@/assets/images/sqfl_banner2.png"
        @click="$router.push('/community')"
        class="community"
        width="100%"
      />
    </div>
    <van-dialog
      v-model="fyTipsShow"
      :showConfirmButton="false"
      :showCancelButton="false"
      className="fyTips"
      v-if="!promoter"
    >
      <svg-icon icon-class="close" @click="fyTipsShow = false" />
      <div class="header">提示</div>
      <div class="content">
        是否加入正品汇分佣推广?<br />
        加入后享受专属权益哦~<br />
      </div>
      <div class="footer">
        <button class="more" @click="$router.push('/promotion/instructions')">
          了解更多
        </button>
        <button
          class="authentication"
          @click="$router.push(`/promotion/promotionRegistration`)"
        >
          去认证
        </button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "mine",
  data() {
    return {
      name: "",
      actualName: "",
      fyTipsShow: false,
    };
  },
  computed: {
    ...mapState("user", [
      "isLogin",
      "userInfo",
      "bank",
      "maxIntegralBank",
      "promoter",
    ]),
  },
  filters: {
    Tel(val) {
      return val.substr(0, 3) + "****" + val.substr(7);
    },
  },
  mounted() {},
  created() {
    if (this.isLogin && this.userInfo.userEntity.actualName)
      this.actualName = this.userInfo.userEntity.actualName;
  },

  methods: {
    onLogin() {
      this.isLogin
        ? this.$router.push("/userData")
        : this.$router.push("/login");
    },
    toSet() {
      if (this.$store.state.App) {
        let info = {
          type: 3,
          data: "",
        };
        Android.callAndroid(JSON.stringify(info));
      } else {
        this.$router.push("/set");
      }
    },
    fyTips() {
      // if (this.promoter) {
        this.$router.push("/promotion");
      // } else {
      //   this.fyTipsShow = true;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.mine_page {
  background: #f5f5f5;
  height: 100vh;
}
.mine_bg {
  background: url("../../assets/images/me_bg.png") no-repeat top;
  background-size: 100%;
  height: 100%;
  padding: 0 15px;
}
.mine_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 16px;
  .portrait {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .navbar_dcs {
    width: 10px;
  }
  .mine_top_left {
    display: flex;
    align-items: center;
    // overflow: hidden;
    width: 100%;
    .mine_name {
      display: flex;
      margin-left: 20px;
      flex-direction: column;
      // width: 80%;
      flex: 1;
      span img {
        width: 6px;
        margin-left: 6px;
      }

      div {
        overflow-x: scroll;
        overflow-y: hidden;
        // white-space: nowrap;
        margin-top: 2px;
        // height: 26px;
        display: flex;
        i {
          margin: 2px 10px 0;
          position: relative;
          display: inline-block;
          font-size: 12px;
          line-height: 20px;
          font-weight: 600;
          border-radius: 6px;
          border: 1px solid #ffe974;
          // #ff8901； #fda305
          background: linear-gradient(
            180deg,
            #f38504 0%,
            #faa508 15%,
            #f96802 50%,
            #faa508 95%,
            #fda305 100%
          );
          padding: 0 6px 0 14px;
          flex-shrink: 0;
          // white-space: nowrap;
          letter-spacing: 1px;
        }
        img {
          position: absolute;
          width: 28px;
          left: -14px;
          top: -5px;
        }
        i:last-child {
          margin-right: 2px;
        }
      }
      div::-webkit-scrollbar {
        display: none;
      }
    }
    .sign_in {
      position: relative;
      right: -15px;
      padding: 9px 12px;
      border-radius: 99px 0 0 99px;
      font-weight: bold;
      color: #333333;
      background: rgba($color: #fff, $alpha: 0.75);
      flex-shrink: 0;
    }
  }
}
.order {
  background: #fff;
  padding: 16px 12px;
  margin-bottom: 12px;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}
.order_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 6px;
    margin-left: 5px;
  }
  span:first-child {
    font-weight: 600;
    color: #333;
  }
  span:last-child {
    color: #666;
  }
}
.order_list {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  .list {
    width: 25%;
    margin-top: 24px;
    text-align: center;
    span {
      display: block;
      padding-top: 5px;
      font-size: 12px;
    }
    img {
      width: 32px;
    }
  }
  // .list:not(:nth-child(4n)) {
  //   margin-right: calc(4% / 3);
  // }
}
::v-deep .van-overlay {
  z-index: 10000 !important;
}
::v-deep .fyTips {
  z-index: 10001 !important;
  .van-dialog__content {
    text-align: center;
    .svg-icon {
      color: #fff;
      right: 23px;
      top: 16px;
      position: absolute;
    }
    padding: 0;
    .header {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .content {
      line-height: 30px;
      padding-bottom: 30px;
    }
    .footer {
      button {
        border: 1px solid #ffc426;
        padding: 10px 22px;
        font-size: 16px;
        border-radius: 99px;
      }
      .more {
        background: #fff;
        color: #ffc426;
        margin-right: 8px;
      }
      .authentication {
        background: #ffc426;
        color: #fff;
        margin-left: 8px;
      }
    }
  }
}
</style>
